.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ebebeb;
  color: #333;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 50%;
}

.uploadBox {
  text-align: center;
  margin-bottom: 20px;
}

.uploadBoxText {
  font-size: 18px;
}

.iconRow {
  margin-top: 20px;
}

.icon {
  font-size: 24px;
  margin: 0 5px;
}

.success {
  margin-bottom: 32px;
}

.link {
  padding: 8px;
  border: 1px solid #4062bb;
  color: #4062bb;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.copyButton {
  padding: 8px;
  border: 1px solid #4062bb;
  color: #4062bb;
  border-radius: 5px;
  min-height: 37px;
  margin-left: 8px;
}

.refresh {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  padding: 8px;
  color: #f45b69;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}